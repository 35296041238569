<template>
  <div class="font-visby text-new-black py-5 cursor-pointer bg-white rounded-xl w-full px-5 mb-3  shadow-soft" :class="{'opacity-60' : !unread}" @click="validateRoute(heading)">
    <div v-if="unread" class="inline-block rounded-lg px-2 mb-2" :style="{'background-color': currentCourseColor3, 'color': currentCourseColor}">
      <div>neu</div>
    </div>
    <div class="text-xl mb-2 font-bold">{{ heading }}</div>
    <div>{{ text }}</div>
  </div>
</template>

<script>
// Mixins
import colors from '../../../mixins/colors.vue';

export default {
  name: 'singleNotification',
  props: ['heading', 'text', 'unread'],
  mixins: [colors],

  methods: {
    validateRoute(text) {
      if (text && text.includes('Battle')) {
        this.$router.push({ name: 'training' });
      }
    },
  },
};
</script>
