<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'readAllNotificationsMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const readAllNotificationsMutation = gql`
      mutation($id: ID!, $notifications: [ComponentNotificationsNotificationsInput!]) {
        updateUserData(id: $id, data: { notifications: $notifications }) {
          data {
            id
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: readAllNotifications } = useMutation(readAllNotificationsMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'readAllNotifications', result: readAllNotifications });
  },
};
</script>
